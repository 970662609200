<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="content">
      <!-- 终端状态 -->
      <div class="search">
        <div
          class="search-status status-all"
          :class="![0, 1, 2, 3].includes(gunStatus) ? 'active' : ''"
          @click="changeStatusFn()">
          <div class="num">
            {{
              Number(pileData.freeGunNum) +
              Number(pileData.chargingGunNum) +
              Number(pileData.faultGunNum) +
              pileData.offLineGunNum
            }}
          </div>
          <div class="text">全部</div>
        </div>
        <div
          class="search-status status-2"
          :class="gunStatus === 3 ? 'active' : ''"
          @click="changeStatusFn(3)">
          <div class="num">{{ Number(pileData.chargingGunNum) || 0 }}</div>
          <div class="text">充电中</div>
        </div>
        <div
          class="search-status status-1"
          :class="gunStatus === 2 ? 'active' : ''"
          @click="changeStatusFn(2)">
          <div class="num">{{ Number(pileData.freeGunNum) || 0 }}</div>
          <div class="text">空闲</div>
        </div>
        <div
          class="search-status status-4"
          :class="gunStatus === 0 ? 'active' : ''"
          @click="changeStatusFn(0)">
          <div class="num">{{ Number(pileData.offLineGunNum) || 0 }}</div>
          <div class="text">离线</div>
        </div>
        <div
          class="search-status status-3"
          :class="gunStatus === 1 ? 'active' : ''"
          @click="changeStatusFn(1)">
          <div class="num">{{ Number(pileData.faultGunNum) || 0 }}</div>
          <div class="text">故障</div>
        </div>
      </div>
      <div class="search-info">
        <div class="search-info-card" v-for="(item, index) in pileInfoData" :key="index">
          <div class="image img0" v-if="item.status === 0"><span>离线</span></div>
          <div class="image img1" v-if="item.status === 1"><span>故障</span></div>
          <div class="image img2" v-if="item.status === 2"><span>空闲</span></div>
          <div class="image img3" v-if="item.status === 3">
            <span>{{ item.soc }}%</span>
          </div>
          <div class="value">
            <div class="code">
              <span>{{ item.gunCode }}</span>
              <span class="copy" @click="copyGunCode(item.gunCode)"></span>
            </div>
            <div class="station-name">{{ item.stationName }}</div>
            <div class="value-status status0" v-if="item.status === 0">
              <span>离线</span>
              {{ item.statusChangeTime ? `${item.statusChangeTime}分钟` : '' }}
            </div>
            <div class="value-status status1" v-if="item.status === 1">
              <span>故障</span>
              {{ item.statusChangeTime ? `${item.statusChangeTime}分钟` : '' }}
            </div>
            <div class="value-status status3" v-if="item.status === 3">
              <span>预计剩余</span>
              {{ item.leftTime || 0 }}分钟
            </div>
          </div>
          <div class="pileType" :class="item.pileType === '2' ? 'exchange' : ''">
            {{ item.pileType === '1' ? '直流' : item.pileType === '2' ? '交流' : '——' }}
          </div>
        </div>
      </div>

      <ykc-pagination
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :current-page.sync="pageInfo.current"
        @current-change="handlePaginationCurrentChange"
        :page-size.sync="pageInfo.size"
        @size-change="handlePaginationSizeChange"></ykc-pagination>
    </div>
  </scroll-layout>
</template>
<script>
  import { barrierPage, workbench } from '@/service/apis';

  export default {
    data() {
      return {
        stationIdData: [],
        searchParams: {
          stationId: null,
          pileType: null, // 1=直流 2=交流
        },
        pileInfoData: [],
        pileData: {}, // 电桩数据
        gunStatus: null, // 0=离线 1=故障 2=空闲 3=充电中  全部 不传
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 15,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            remote: true,
            label: '电站名称',
            key: 'stationId',
            comName: 'YkcDropdown',
            placeholder: '请选择电站名称',
            data: this.stationIdData,
          },
          {
            label: '终端类型',
            key: 'pileType',
            comName: 'YkcDropdown',
            placeholder: '请选择终端类型',
            data: [
              { id: 1, name: '直流' },
              { id: 2, name: '交流' },
            ],
          },
        ];
      },
    },
    created() {
      const { gunStatus } = this.$route.query;
      if ([0, 1, 2, 3, 4].includes(gunStatus)) {
        this.gunStatus = gunStatus;
        console.log('gunStatus', this.gunStatus);
      }
      this.requestStationData();
      this.queryMonitorCount();

      this.queryMonitorPage();
    },
    methods: {
      /**
       * 获取归属电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      // 电桩状态改变
      changeStatusFn(status) {
        console.log('gunStatus', status);
        this.gunStatus = status;
        this.pageInfo.current = 1;
        this.queryMonitorCount();
        this.queryMonitorPage();
      },
      // 查询电桩监控
      queryMonitorCount() {
        const params = {
          ...this.searchParams,
          // gunStatus: this.gunStatus,
        };
        workbench
          .monitorCount(params)
          .then(res => {
            console.log('res', res);
            this.pileData = res || {};
          })
          .catch(err => console.log(err));
      },
      // 电桩监控分页查询
      queryMonitorPage() {
        const params = {
          ...this.pageInfo,
          ...this.searchParams,
          gunStatus: this.gunStatus,
        };
        workbench.monitorPage(params).then(res => {
          this.pileInfoData = res.records;
          this.pageInfo.total = res.total || 0;
        });
      },
      // 复制
      copyGunCode(gunCode) {
        console.log('gunCode', gunCode);
        this.$copyText(gunCode)
          .then((e, err) => {
            if (e) {
              this.$message.success('复制成功');
            }
            if (err) {
              this.$message.error('复制失败');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        Object.assign(this.searchParams, form);
        this.queryMonitorCount();
        this.queryMonitorPage();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.queryMonitorPage();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.queryMonitorPage();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    // background: #ffffff;
    padding-bottom: 20px;

    .search {
      display: flex;
      justify-content: flex-start;
      padding: 23px 0px 22px 27px;
      &-status {
        width: 128px;
        height: 80px;
        background: #f2f9ff;
        border: 1px solid rgba(77, 140, 253, 0.17);
        display: flex;
        flex-direction: column;
        .num {
          margin-top: 15px;
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
          text-align: center;
        }
        .text {
          font-weight: 500;
          font-size: 12px;
          color: #4a4a4a;
          line-height: 17px;
          text-align: left;
          text-align: center;
        }
      }
      .active {
        background: #4d8cfd;
        box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
        .num {
          color: #ffffff !important;
        }
        .text {
          color: #ffffff;
        }
      }
      .status-1 {
        .num {
          color: #15c350;
        }
      }
      .status-2 {
        .num {
          color: #005bff;
        }
      }
      .status-3 {
        .num {
          color: #ff0000;
        }
      }
      .status-4 {
        .num {
          color: #fa6400;
        }
      }
    }

    .search-info {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 27px 0 27px;
      &-card {
        width: 310px;
        height: 158px;
        margin-right: 39px;
        margin-bottom: 36px;
        background: #ffffff;
        box-shadow: 1px 1px 4px 1px rgba(66, 66, 66, 0.09);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        position: relative;
        .image {
          margin-top: 40px;
          width: 68px;
          height: 76px;
          background: #fa6400;
          text-align: center;
        }
        .img0 {
          background: url('~@/assets/images/staging/offline.png') no-repeat 100%;
          span {
            font-size: 10px;
            line-height: 13px;
            position: relative;
            top: 50%;
            color: #ffff76;
          }
        }
        .img1 {
          background: url('~@/assets/images/staging/fault.png') no-repeat 100%;
          span {
            font-size: 10px;
            line-height: 13px;
            position: relative;
            top: 50%;
            color: #ffffff;
          }
        }
        .img2 {
          background: url('~@/assets/images/staging/Idle.png') no-repeat 100%;
          span {
            line-height: 21px;
            font-size: 16px;
            position: relative;
            top: 27px;
            color: #ffffff;
          }
        }
        .img3 {
          background: url('~@/assets/images/staging/charging.png') no-repeat 100%;
          span {
            line-height: 21px;
            font-size: 16px;
            position: relative;
            top: 27px;
            color: #ffffff;
          }
        }
        .value {
          margin: 40px 0 0 20px;
          color: #000000;
          text-align: left;

          .code {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            .copy {
              visibility: hidden;
              margin-left: 6px;
              width: 14px;
              background: url('~@/assets/images/staging/copy.png') no-repeat 100%;
            }
            &:hover {
              .copy {
                visibility: visible;
              }
            }
          }
          .station-name {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            margin-top: 8px;
            max-width: 130px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &-status {
            margin-top: 14px;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            text-align: left;
            span {
              font-weight: 500;
              font-size: 10px;
              line-height: 14px;
              text-align: justify;
            }
          }
          .status0 {
            color: #fa6400;
          }
          .status1 {
            color: #e02020;
          }
          .status3 {
            color: #4d8cfd;
          }
        }
        .pileType {
          width: 43px;
          height: 18px;
          background: #000000;
          font-weight: 600;
          font-size: 10px;
          color: #ffffff;
          line-height: 18px;
          text-align: center;
          border-radius: 0px 0px 0px 6px;
          position: relative;
          right: -25px;
          top: 0;
        }
        .to-be-filled {
          width: 80px;
          height: 40px;
          position: relative;
          right: 0;
          bottom: 0;
        }
        .exchange {
          background: #0091ff !important;
        }
      }
    }
  }
</style>
